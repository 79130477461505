import {
  TWINCAT_ADMIN_SETTINGS,
  TWINCAT_SETTINGS,
} from "../Actions/ApiActions";
import { DARK_MODE_SWITCHER } from "../Actions/AppActions";

export const initialState = {
  settings: {
    isDarkMode: localStorage.getItem("mode") === "DARK" ? false : true,
  },
};

export default function SettingsReducer(state = initialState, action) {
  switch (action.type) {
    case DARK_MODE_SWITCHER:
      let newState = state.settings;
      if (state.settings.isDarkMode) {
        localStorage.setItem("mode", "LIGHT");
      } else {
        localStorage.setItem("mode", "DARK");
      }
      newState.isDarkMode = !state.settings.isDarkMode;
      return { ...state, settings: newState };
    case TWINCAT_SETTINGS:
      return {
        ...state,
        settings: { ...action.payload, isDarkMode: state.settings.isDarkMode },
      };
    case TWINCAT_ADMIN_SETTINGS:
      return {
        ...state,
        settings: { ...action.payload, isDarkMode: state.settings.isDarkMode },
      };
    default:
      return state;
  }
}
